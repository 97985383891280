
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$variable-prefix}gray-500);
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}


.apexcharts-gridline line{
    stroke: var(--#{$variable-prefix}gray-300);
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-tooltip{
    border: 1px solid $border-color !important;
    background-color: $card-bg !important;
    box-shadow: $box-shadow !important;
    * {
        font-family: $font-family-base !important;
        color:  var(--#{$variable-prefix}gray-600) !important;
    }

    .apexcharts-tooltip-title{
        background-color: rgba(var(--#{$variable-prefix}light-rgb), .75) !important;
        border-bottom: 1px solid $border-color !important;
    }
}

.apexcharts-tooltip.apexcharts-theme-dark{
    * {
        color: $white !important;
    }
}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-radialbar-track.apexcharts-track {
    path {
        stroke: var(--#{$variable-prefix}gray-300);
    }
}

.apexcharts-legend-text {
    color: var(--#{$variable-prefix}gray-500) !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$variable-prefix}gray-500);
    }
}
